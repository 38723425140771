import { useEffect, useState } from 'react'
import Link from 'next/link';
import Image from 'next/image';
import dynamic from "next/dynamic";
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoggedIn, profileState } from "@recoil/atoms/profile";
import { signout } from "@api/authentication";
import { useRouter } from "next/router";
import PopupComponent from '@components/popup';
import { sanityIoImageLoader } from '@core/utils';
import { MdEdit, MdChevronRight, MdShoppingBag, MdBookmark } from "react-icons/md"
import { AiTwotoneSetting } from "react-icons/ai"
import { MdPayments } from "react-icons/md"
import { MdPersonPinCircle } from "react-icons/md"
import { MdLogout, MdShoppingCart } from "react-icons/md"
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
} from '@chakra-ui/react'
import { GrAdd } from "react-icons/gr";
import { GrSubtract } from "react-icons/gr";
import { CgMoreO } from 'react-icons/cg';
import { FaClipboardList } from 'react-icons/fa';

const Header = dynamic(() => import("../header").then((mod) => mod.Header), {
    ssr: false,
});


const Navbar = dynamic(
    () => import('../navbar').then((mod) => mod.Navbar),
    {
        ssr: false,
    }
);

interface Props {
    homeLink?: string
    isShowSidebar?: boolean

}

export const Sidebar: React.FC<Props> = ({ homeLink, isShowSidebar }) => {
    const [showSidebar, setShowSidebar] = useState(isShowSidebar);
    const [userLoggedIn, setUserLoggedIn] = useRecoilState(isLoggedIn);
    const [showModal, setShowModal] = useState(false);
    const profile = useRecoilValue(profileState)
    const router = useRouter();

    const orderId = typeof window !== "undefined" && localStorage.getItem("orderId");

    const signOutCallback = () => {
        signout().then((res) => {
        }).catch((err) => {
        }).finally(() => {
            setUserLoggedIn(false);
            localStorage.removeItem('token')
            localStorage.removeItem('profile')
            document.cookie = "access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
            setShowModal(false);
            setShowSidebar(false);
            router.push("/");
        });
    }

    useEffect(() => {
        setUserLoggedIn(localStorage.getItem('token')?.length > 0)

    }, [router.isReady])

    return (
        <div>
            {showSidebar ? (
                <div>
                    <div className={`top-0 left-0 w-72 md:w-1/2 lt-sm:w-full bg-neutral-100 overflow-auto ${showSidebar ? "transition duartion-0" : "transition duartion-0"} text-neutral-50 fixed h-full z-40 ease-in-out`}>
                        <Header />
                        <div className="flex flex-col gap-3 p-4">
                            {userLoggedIn ?
                                <>
                                    <div className="grid p-4 bg-neutral-50 rounded-md">
                                        <div className="flex justify-between ">
                                            <div className="flex gap-2 ">
                                                <Image
                                                    loader={sanityIoImageLoader}
                                                    src={profile?.profile_image || "/consumer/circular-logo.png"}
                                                    width="45"
                                                    height="45"
                                                    alt="logo"
                                                    loading="eager"
                                                    className="rounded-full"
                                                />
                                                <div className="grid gap-1 items-center text-neutral-900">
                                                    <p className="text-xl text-neutral-900 font-manrope font-semibold">{profile?.first_name}</p>
                                                    <p className="text-sm text-neutral-900 font-manrope font-medium">+91 {profile?.phone}</p>
                                                </div>
                                            </div>
                                            <div className="flex text-xl items-center text-neutral-900">
                                                <Link href={"/account/profile"}>
                                                    <MdEdit />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div></div>
                                </>
                                :
                                <div className="grid gap-4 grid-cols-2 p-4 bg-neutral-50 rounded-md">
                                    <Link href="/login">
                                        <button className="whitespace-nowrap border border-gray-900 text-xl text-neutral-900 w-full rounded-md bg-neutral-50 font-semibold p-1 font-semibold">Login</button>
                                    </Link>
                                    <Link href="/signup">
                                        <button className="whitespace-nowrap border border-black text-xl text-neutral-50 w-full rounded-md bg-neutral-900 font-semibold p-1 font-semibold">Sign Up</button>
                                    </Link>
                                </div>
                            }
                            {/* <Link href={homeLink || "/"}><div className="text-base font-manrope bg-neutral-50 rounded-md font-semibold text-neutral-900 p-2">Home</div></Link> */}
                            {(orderId !== null && orderId !== undefined) && <Link href={'/cart/vieworder'}>
                                <div className="flex justify-between bg-neutral-50 rounded-md py-3 px-2">
                                    <div className="flex gap-2">
                                        <span className="flex items-center text-xl font-semibold text-neutral-900"><FaClipboardList /></span>
                                        <span className="flex items-center text-base font-semibold text-neutral-900"> Your Recent Order</span>
                                    </div>
                                    <span className="flex items-center text-xl text-neutral-900 font-semibold">
                                        <MdChevronRight />
                                    </span>
                                </div>
                            </Link>}
                            <Link href={'/cart'}>
                                <div className="flex justify-between bg-neutral-50 rounded-md py-3 px-2">
                                    <div className="flex gap-2">
                                        <span className="flex items-center text-xl font-semibold text-neutral-900"><MdShoppingCart /></span>
                                        <span className="flex items-center text-base font-semibold text-neutral-900"> My Cart</span>
                                    </div>
                                    <span className="flex items-center text-xl text-neutral-900 font-semibold">
                                        <MdChevronRight />
                                    </span>
                                </div>
                            </Link>
                            <Link href={'/order/me'}>
                                <div className="flex justify-between bg-neutral-50 rounded-md py-3 px-2">
                                    <div className="flex gap-2">
                                        <span className="flex items-center text-xl font-semibold text-neutral-900"><MdShoppingBag /></span>
                                        <span className="flex items-center text-base font-semibold text-neutral-900"> My Orders</span>
                                    </div>
                                    <span className="flex items-center text-xl text-neutral-900 font-semibold">
                                        <MdChevronRight />
                                    </span>
                                </div>
                            </Link>
                            <Link href={'/service/me'}>
                                <div className="flex justify-between bg-neutral-50 rounded-md py-3 px-2">
                                    <div className="flex gap-2">
                                        <span className="flex items-center text-xl font-semibold text-neutral-900"><MdBookmark /></span>
                                        <span className="flex items-center text-base font-semibold text-neutral-900"> My Bookings</span>
                                    </div>
                                    <span className="flex items-center text-xl text-neutral-900 font-semibold">
                                        <MdChevronRight />
                                    </span>
                                </div>
                            </Link>
                            <Link href={'/account/addresses'}>
                                <div className="flex justify-between bg-neutral-50 rounded-md py-3 px-2">
                                    <div className="flex gap-2">
                                        <span className="flex items-center text-xl font-semibold text-neutral-900"><MdPersonPinCircle /></span>
                                        <span className="flex items-center text-base font-semibold text-neutral-900"> My Addresses</span>
                                    </div>
                                    <span className="flex items-center text-xl text-neutral-900 font-semibold">
                                        <MdChevronRight />
                                    </span>
                                </div>
                            </Link>
                            {/* <Link href={'/account'}><div className="text-base font-manrope bg-neutral-50 rounded-md font-semibold text-neutral-900 p-2">My Account</div></Link> */}
                            <Link href={''}>
                                <div className="flex justify-between bg-neutral-50 rounded-md py-3 px-2">
                                    <div className="flex gap-2">
                                        <span className="flex items-center text-xl font-semibold text-neutral-900"><AiTwotoneSetting /></span>
                                        <span className="flex items-center text-base font-semibold text-neutral-900">Setting</span>
                                    </div>
                                    <span className="flex items-center text-xl text-neutral-900 font-semibold">
                                        <MdChevronRight />
                                    </span>
                                </div>
                            </Link>
                            <Link href={'/refer'}>
                                <div className="flex justify-between bg-neutral-50 rounded-md py-3 px-2">
                                    <div className="flex gap-2">
                                        <span className="flex items-center text-xl font-semibold text-neutral-900"><MdPayments /></span>
                                        <span className="flex items-center text-base font-semibold text-neutral-900">Refer and Earn</span>
                                    </div>
                                    <span className="flex items-center text-xl text-neutral-900 font-semibold">
                                        <MdChevronRight />
                                    </span>
                                </div>
                            </Link>
                            <div>
                                <Accordion allowMultiple>
                                    <AccordionItem>
                                        {({ isExpanded }) => (
                                            <>
                                                <div className={`flex bg-neutral-50 ${isExpanded ? "rounded-t-md" : "rounded-md"} py-3 px-2`}>
                                                    <AccordionButton>
                                                        <Box as="span" flex='1' textAlign='left'>
                                                            <span className="flex gap-2 items-center text-base font-semibold text-neutral-900"><CgMoreO />More</span>
                                                        </Box>
                                                        {isExpanded ? (
                                                            <span className="flex items-center text-md text-neutral-900 font-semibold">
                                                                <GrSubtract />
                                                            </span>

                                                        ) : (
                                                            <span className="flex items-center text-md text-neutral-900 font-semibold">
                                                                <GrAdd />
                                                            </span>

                                                        )}
                                                    </AccordionButton>
                                                </div>
                                                <AccordionPanel pb={4}>
                                                    <div className={`flex py-2 px-4 bg-neutral-50 flex-col gap-2 ${isExpanded ? "rounded-b-md" : ""}`}>
                                                        <Link href={'/about'}><div className="text-base font-semibold text-neutral-900">About Us</div></Link>
                                                        <Link href={'/terms'}><div className="text-base font-semibold text-neutral-900">Terms of Use</div></Link>
                                                        <Link href={'/privacy'}><div className="text-base font-semibold text-neutral-900">Privacy Policy</div></Link>
                                                        <Link href={'/refund'}><div className="text-base font-semibold text-neutral-900">Refund Policy</div></Link>
                                                        <Link href={'/return'}><div className="text-base font-semibold text-neutral-900">Return Policy</div></Link>
                                                        <Link href={'/shipping'}><div className="text-base font-semibold text-neutral-900">Shipping Policy</div></Link>
                                                        <Link href={'https://business.jhattse.com/signup'}><div className="text-base font-semibold text-neutral-900">Partner with us</div></Link>
                                                        <Link href={'https://business.jhattse.com/products/seller-software-for-brands'}><div className="text-base font-semibold text-neutral-900">Sell on Jhattse</div></Link>
                                                    </div>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                            </div>

                            {userLoggedIn ?
                                <>
                                    <div className="flex gap-2 bg-neutral-50 rounded-md py-3 px-2">
                                        <span className="flex items-center text-xl font-semibold text-error-500"><MdLogout /></span>
                                        <button className="flex justify-start text-error-500 text-base font-manrope font-semibold w-full" onClick={() => setShowModal(!showModal)} >Log Out</button>
                                    </div>
                                </>
                                :
                                <>
                                </>}
                        </div>
                        <Navbar />
                    </div>
                </div>
            ) : (
                <></>
            )
            }
            <svg
                onClick={() => setShowSidebar(!showSidebar)}
                className="z-30 flex items-center cursor-pointer "
                fill="#FFFFFF"
                viewBox="0 0 100 80"
                width="30"
                height="30"
            >
                <rect width="100" height="10"></rect>
                <rect y="30" width="100" height="10"></rect>
                <rect y="60" width="100" height="10"></rect>
            </svg>

            {/* Confirm Popup */}
            <PopupComponent
                closeBtnName='Go Back'
                btnName='Yes Log Out'
                showModal={showModal}
                setShowModal={setShowModal}
                title={<><span className='font-semibold text-lg'>Log Out ?</span><p className="block font-semibold text-base text-neutral-500">Are you sure to log out your account?</p></>}
                ActionFun={signOutCallback}
            />
        </div >
    )
}



import { Modal } from "antd";

interface Props {
  showModal: boolean
  setShowModal: Function
  title?: string | React.ReactNode
  message?: string;
  ActionFun?: Function
  btnName?: string
  closeBtnName?: string
}
 
export default function PopupComponent({
  showModal,
  setShowModal,
  title,
  message,
  ActionFun,
  btnName,
  closeBtnName,
}: Props) {
  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      centered
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <h6 className="text-lg text-neutral-800 font-manrope font-semibold">
            {title}
          </h6>

          {message && (
            <p className="text-base text-neutral-600 font-manrope font-medium">
              {message}
            </p>
          )}
        </div>

        <div className="flex justify-end gap-2.5">
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="h-10 px-4 text-sm text-brand-500 font-manrope font-semibold transition-all duration-200 cursor-pointer hover:opacity-80 rounded-md border border-solid border-brand-500"
          >
            {closeBtnName ? closeBtnName : "Close"}
          </button>

          <button
            type="button"
            onClick={() => ActionFun()}
            className="h-10 px-4 text-sm text-white font-manrope font-semibold transition-all duration-200 cursor-pointer hover:opacity-80 rounded-md bg-brand-500"
          >
            {btnName ? btnName : "Confirm"}
          </button>
        </div>
      </div>
    </Modal>
  );
}